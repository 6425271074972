
let resetButton = document.querySelector('.ce_rsce_fahrzeugSucheAPI .reset');

let searchButton = document.getElementById('searchButton');


if (document.querySelector('.ce_rsce_fahrzeugSucheAPI, .mod_FahrzeugSuche')) {
    let jumpToFormular = document.querySelector('.mod_FahrzeugSuche .JumptoFormular');
    if (jumpToFormular){
        jumpToFormular.addEventListener('click', () => {
            let formular = document.querySelector('.mod_FahrzeugSuche .kontakt');
            formular.scrollIntoView({behavoir: 'smooth'});
        })
    }

    let whatsappButtons = document.querySelectorAll('.mod_FahrzeugSuche .whatsappShare, .ce_rsce_fahrzeugSucheAPI .whatsappShare');
    if (whatsappButtons.length > 0){
        whatsappButtons.forEach(whatsappButton => {
            whatsappButton.addEventListener('click', () => {
                var pageUrl = encodeURIComponent(window.location.href);
                var whatsappUrl = "https://wa.me/?text=" + pageUrl;
                window.open(whatsappUrl, '_blank');
            })
        })
    }

    let printButtons = document.querySelectorAll('.mod_FahrzeugSuche .print, .ce_rsce_fahrzeugSucheAPI .print');
    if (printButtons.length > 0){
        printButtons.forEach(printButton => {
            printButton.addEventListener('click', () => {
                window.print();
            })
        })
    }

    if (document.getElementById('form_anfrage')){
        document.getElementById('form_anfrage').addEventListener('submit', (event)=>{
            event.preventDefault();
            event.stopPropagation();

            sendDetailForm();
            setTimeout(function(){
                window.location.href = 'https://www.auto-roehr.de/nachricht-erhalten.html'
            },500)
            
        })
    }

}


if (document.querySelector('.ce_rsce_fahrzeugSucheAPI')) {

    setTimeout(function(){
        // Immer zum Seitenanfang springen
        let fahrzeugAPI = document.querySelector('.ce_rsce_fahrzeugSucheAPI');
        if (fahrzeugAPI && window.location.href.includes('fahrzeugsuche.html')) {
            let elementPosition = fahrzeugAPI.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: elementPosition - 150, behavior: 'smooth' });
        }
    }, 500);
    
    setTimeout(function(){
        
       
        let finanzierungsbuttons = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .leasinghinweis');
        if (finanzierungsbuttons.length > 0){
            finanzierungsbuttons.forEach(finanzButton => {
                finanzButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    let finanzContent = finanzButton.parentElement.parentElement.parentElement.parentElement.querySelector('.finanzierung');
                    if (finanzContent.classList.contains('active')){
                        finanzContent.classList.remove('active');
                    } else {
                        finanzContent.classList.add('active');
                    }
                });
            });
            finanzierungsbuttons.forEach(finanzButton => {
                finanzButton.addEventListener('mouseover', (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    let finanzContent = finanzButton.parentElement.parentElement.parentElement.parentElement.querySelector('.finanzierung');
                    if (finanzContent.classList.contains('active')){
                        setTimeout(()=> {
                            finanzContent.classList.remove('active');
                        },100)
                    } else {
                        setTimeout(()=> {
                            if (finanzButton.matches(':hover')){
                                finanzContent.classList.add('active');
                            }
                        },100)
                    }
                });
            });

        }

        let markeSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name="marke"]');
        if (markeSelect){
            markeSelect.addEventListener('change', ()=> {
                document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name="modell"]').selectedIndex = 0;
                document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name="karosserieform"]').selectedIndex = 0;

                updateFilter(markeSelect);
                updateFilter(document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name="modell"]'));
            });
        }
       
        linkFilter();

    },1000)
    

    
    let prefilter = document.querySelector('.ce_rsce_fahrzeugSucheAPI').getAttribute('data-filterKlasse');
    if (prefilter){
        preFilter();
    }
    

    let quickfilters = document.querySelector('.ce_rsce_fahrzeugSucheAPI').querySelectorAll('.filter-icons .icon');
    quickfilters.forEach(quickfilter => {
        quickfilter.addEventListener('click', (element)=> {
            quickFilter(element.target);
        })
    })
   

    searchButton.addEventListener('click', () => {
        let fahrzeuge = document.querySelector('.ce_rsce_fahrzeugSucheAPI .filter-container.buttons');
        fahrzeuge.scrollIntoView({behavoir: 'smooth'});
    })


    
    var filtercontainer = document.querySelector('.filter-container.select')
    function getFilters() {


        setTimeout(function(){
            countCars();
        },200)

        const filterOptions = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI select, .ce_rsce_fahrzeugSucheAPI input');
        filterOptions.forEach(filterOption => {
            if (filterOption.getAttribute('name') === 'sort'){
                filterOption.addEventListener('change', ()=> {
                    filterObject['sort'] = [];
                    filterObject['sort'].push(filterOption.value);
                    sortCars();
                })
            } else if (filterOption.getAttribute('name') === 'freitext'){
                filterOption.addEventListener('input', ()=> {
                    updateFilter(filterOption);
                })
            } else {
                filterOption.addEventListener('change', ()=> {
                    updateFilter(filterOption);
                });
            }
        })
    



        /*
        var formData = new FormData();
        formData.append('action', 'getFilters');
    
        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://www.auto-roehr.de/fahrzeugAPI.php', true);
        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                  
                    //filtercontainer.innerHTML = xhr.responseText;
                    filtercontainer.innerHTML = filtercontainer.innerHTML;

                    console.log('Filter geladen');

                    setTimeout(function(){
                        countCars();
                    },200)

                    const filterOptions = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI select, .ce_rsce_fahrzeugSucheAPI input');
                    filterOptions.forEach(filterOption => {
                        if (filterOption.getAttribute('name') === 'sort'){
                            filterOption.addEventListener('change', ()=> {
                                filterObject['sort'] = [];
                                filterObject['sort'].push(filterOption.value);
                                sortCars();
                            })
                        } else if (filterOption.getAttribute('name') === 'freitext'){
                            filterOption.addEventListener('input', ()=> {
                                updateFilter(filterOption);
                            })
                        } else {
                            filterOption.addEventListener('change', ()=> {
                                updateFilter(filterOption);
                            });
                        }
                    })
                } else {
                    console.log('Fehler beim Laden der Filter.');
                }
            }
        };
        xhr.send(formData);
        */
    }

    if (resetButton){
        resetButton.addEventListener('click', ()=> {
            let quickfilter = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .filter-icons .icon');
            quickfilter.forEach(icon => {
                icon.classList.remove('active');
            })
            resetFilter();
        });
    }

    getFilters();    

}






let filterElements = {
    klasse: [],
    marke: [],
    modell: [],
    karosserieform: []
}


let filterObject = {
    freitext: [],
    klasse: [],
    marke: [],
    modell: [],
    karosserieform: [],
    fahrzeugart: [],
    aufbauart: [],
    ez_ab: [],
    km_bis: [],
    preis_bis: [],
    leistung: [],
    kraftstoff: [],
    getriebe: [],
    standort: [],
    anhaegekupplung: [],
    navigationssystem: [],
    allradantrieb: [],
    rueckfahrkamera: [],
    standheizung: [],
    ledscheinwerfer: [],
    panoramaschiebedach: [],
    klimaautomatik: [],
    sitzheizung: [],
    abstandstempomat: [],
    tempomat: [],
    soundsystem: [],
    fahrgestellnummer: [],
    fahrzeugnr: [],
    sort: ['preis_aufsteigend']
};


// ##### Filter By URL #####
function linkFilter() {
    setTimeout(function(){
        let fahrzeugsuche = document.querySelector('.ce_rsce_fahrzeugSucheAPI');
        let urlObj = new URL(window.location.href);
        let searchParams = urlObj.searchParams;

        // Iteriere über jeden Parameter in der URL
        for (const [key, value] of searchParams.entries()) {
            let values = value.split(',');

            // Verarbeite Select-Boxen
            let select = fahrzeugsuche.querySelector('select[name=' + key + ']');
            if (select) {
                for (let i = 0; i < select.options.length; i++) {
                    if (values.includes(select.options[i].value)) {
                        select.options[i].selected = true;
                        // Update filterObject und Filter anwenden
                        updateFilter(select);
                    }
                }
                continue;
            }

            // Verarbeite Checkboxes
            let checkboxes = fahrzeugsuche.querySelectorAll('input[type=checkbox][name=' + key + ']');
            if (checkboxes.length > 0) {
                checkboxes.forEach(checkbox => {
                    if (values.includes(checkbox.value)) {
                        checkbox.checked = true;
                        // Update filterObject und Filter anwenden
                        updateFilter(checkbox);
                    }
                });
                continue;
            }

            let quickfilterIcons = fahrzeugsuche.querySelectorAll('.filter-icons .icon')
            quickfilterIcons.forEach(filterIcon => {
                let filter = filterIcon.getAttribute('data-quickfilter');
                if (values.includes(filter)){
                    quickFilter.classList.add('active');
                }
            })

        }
    },300)
    
}



// ##### Update URL #####
function updateURL() {
    let currentUrl = new URL(window.location.href);
    let urlParams = new URLSearchParams(currentUrl.search);

    for (let category in filterObject) {
        if (filterObject[category] && filterObject[category].length > 0) {
            try {
                urlParams.set(category, filterObject[category].join(','));
            } catch (error) {
                console.log(error);
            }
            
        } else {

            urlParams.delete(category);
        }
    }

    currentUrl.search = urlParams.toString();
    
    window.history.pushState(null, '', currentUrl.toString());
}



// ##### Filter Object anpassen #####
function updateFilter(element) {


    console.log('Update Filter');


    let filterName = element.getAttribute('name');
    let item = element.value;

    if (element.tagName === 'SELECT' || filterName === 'freitext') {
        filterObject[filterName] = [];
    }
    
    if (filterObject[filterName].includes(item)) {
        let index = filterObject[filterName].indexOf(item);
        filterObject[filterName].splice(index, 1);
    } else {
        if (item !== "") {
            
            filterObject[filterName].push(item);
        }
    }
    // Update der URL nach Anpassung des Filters
    updateURL();
    updateCars();
}


// ##### Dynamische Filterung #####
function dynamicFilter(){
    let klasseSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name=klasse]');
    let modellSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name=modell]');
    let karosserieSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name=karosserieform]');

    let fahrzeuge = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .fahrzeug:not([style*="display: none"])');

    let sichtbareModelle = new Set(); 
    let sichtbareKarosserieformen = new Set();

    fahrzeuge.forEach(fahrzeug => {
        let modell = fahrzeug.getAttribute('data-modell');
        let karosserieform = fahrzeug.getAttribute('data-karosserieform');
        
        if (modell) {
            sichtbareModelle.add(modell.trim());
        }
        
        if (karosserieform) {
            karosserieform.split(';').forEach(form => {
                if (form.includes(' ')){
                    form = form.slice(form.indexOf(' ') + 1);
                }
                sichtbareKarosserieformen.add(form.trim());
            });
        }
    });

    if (filterObject.marke.length != 0 && filterObject.modell.length === 0 || filterObject.klasse.length != 0 && filterObject.modell.length === 0){
        modellSelect.querySelectorAll('option').forEach((option, index) => {
            if (index === 0) {
                option.classList.remove('hidden');
            } else {
                if (sichtbareModelle.has(option.value)) {
                    option.classList.remove('hidden');
                } else {
                    option.classList.add('hidden');
                }
            }
        });
    }

    if (filterObject.karosserieform.length === 0){
        karosserieSelect.querySelectorAll('option').forEach((option, index) => {
            if (index === 0) {
                option.classList.remove('hidden');
            } else {
                if (sichtbareKarosserieformen.has(option.value)) {
                    option.classList.remove('hidden');
                } else {
                    option.classList.add('hidden');
                }
            }
        });  
        specialFilterKarosserie(); 
    }
}




function specialFilterKarosserie(){
    let karosserieSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name="karosserieform"]');

    if (filterObject.klasse[0] === 'PKW' || filterObject.klasse[0] === 'P'){
        let filterSelect = ['Kombi, Kleinbus bis 9 Sitze', 'Wohnmobil andere', 'Pritsche', 'Kastenwagen hoch', 'Kastenwagen lang', 'Wohnmobil Kastenwagen', 'Kastenwagen'];
        Array.from(karosserieSelect.options).forEach(option => {
            if (filterSelect.includes(option.text)){
                option.classList.add('hidden');
            }
        });
    }

    if (filterObject.klasse[0] === 'NFZ' || filterObject.klasse[0] === 'N'){
        let filterSelect = ['Geländewagen/SUV/Pickup', 'Van/Kleinbus', 'Wohnmobil andere'];
        Array.from(karosserieSelect.options).forEach(option => {
            if (filterSelect.includes(option.text)){
                option.classList.add('hidden');
            }
        });
    }

    if (filterObject.klasse[0] === 'WOMO' || filterObject.klasse[0] === 'W'){
        let filterSelect = ['Van/Kleinbus', 'Kombi, Kleinbus bis 9 Sitze', 'Kastenwagen hoch', 'Kastenwagen'];
        Array.from(karosserieSelect.options).forEach(option => {
            if (filterSelect.includes(option.text)){
                option.classList.add('hidden');
            }
        });
    }
}



// ##### Fahrzeuge filtern #####
function updateCars(){
    
    filterElements = {
        klasse: [],
        marke: [],
        modell: [],
        karosserieform: []
    }
    

    let carCounter = 0;

    let fahrzeuge = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .fahrzeug');

    fahrzeuge.forEach(fahrzeug => {
        let showCar = true;

        if (filterObject['freitext'].length > 0){
            showCar = false;
            let freitext = filterObject['freitext'][0].toLowerCase();
            let suchwoerter = freitext.split(" "); 

            let fahrzeugName = fahrzeug.getAttribute('data-name').toLowerCase();
            showCar = suchwoerter.every(wort => fahrzeugName.includes(wort));

        } else {
            for(let category in filterObject){
                if (category === 'ez_ab'){
                    if (parseInt(filterObject[category]) > parseInt(fahrzeug.getAttribute('data-' + category))){
                        showCar = false;
                        break;
                    }
                } else if (category === 'km_bis'){
                    let filterValue = parseInt(filterObject[category]);
                    let fahrzeugValue = parseInt(fahrzeug.getAttribute('data-' + category));
                    if (filterValue < fahrzeugValue) {
                        showCar = false;
                        break;
                    }
                } else if (category === 'preis_bis'){
                    if (parseInt(filterObject[category]) < parseInt(fahrzeug.getAttribute('data-' + category))){
                        showCar = false;
                        break;
                    }
                } else if (category === 'leistung') {
                    let filterValue = parseInt(filterObject[category]);
                    let fahrzeugValue = parseInt(fahrzeug.getAttribute('data-' + category));
                    if (filterValue > fahrzeugValue) {
                        showCar = false;
                        break;
                    }
                } else if (category === 'modell'){
                    if (fahrzeug.getAttribute('data-' + category).includes(filterObject[category]) === false){
                        showCar = false;
                        break;
                    }
                } else if (category === 'klasse'){
                    if (fahrzeug.getAttribute('data-' + category).includes(filterObject[category]) === false){
                        showCar = false;
                        break;
                    }
                } else if (category === 'sort'){
                    
                } else if (category === 'fahrzeugart'){
                    if (fahrzeug.getAttribute('data-' + category).includes(filterObject[category]) === false){
                        showCar = false;
                        break;
                    }
                } else if (category === 'karosserieform'){
                    if (fahrzeug.getAttribute('data-karosserieform').includes(filterObject[category]) === false){
                        showCar = false;
                    }
                    if (filterObject.klasse === 'PKW' && fahrzeug.getAttribute('data-karosserieform').includes('NFZ')){
                        console.log('teeeeest');
                        showCar = false;
                    }
                } else if (fahrzeug.getAttribute('data-' + category) != filterObject[category] && filterObject[category] != ""){
                    showCar = false;
                    break;
                }
            }
        }

       
        if (showCar){
            carCounter++;
            fahrzeug.style.display = 'block';
            if (filterElements['klasse'].includes(fahrzeug.getAttribute('data-klasse')) === false ){
                filterElements['klasse'].push(fahrzeug.getAttribute('data-klasse'));
            }
            if (filterElements['marke'].includes(fahrzeug.getAttribute('data-marke')) === false ){
                filterElements['marke'].push(fahrzeug.getAttribute('data-marke'));
            }
            if (filterElements['modell'].includes(fahrzeug.getAttribute('data-modell')) === false ){
                filterElements['modell'].push(fahrzeug.getAttribute('data-modell'));
            }
            let fahrzeugkarosserie = fahrzeug.getAttribute('data-karosserieform');
            let karosserieTeile = fahrzeugkarosserie.split(' '); 
            karosserieTeile.forEach(teil => {
                if (!filterElements['karosserieform'].includes(teil)) {
                    filterElements['karosserieform'].push(teil);  
                }
            });
        } else {
            fahrzeug.style.display = 'none';
        }

    
    })


    dynamicFilter();
    
    countCars();

    updateURL();

}


function sortCars(){
    let sort = filterObject['sort'];

    let fahrzeuge = Array.from(document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .fahrzeug'));
    document.querySelector('.ce_rsce_fahrzeugSucheAPI .fahrzeuge').innerHTML = "";

    if (sort[0] === 'preis_aufsteigend'){
        fahrzeuge.sort((a, b) => {
            let preisA = parseInt(a.getAttribute('data-preis_bis'));
            let preisB = parseInt(b.getAttribute('data-preis_bis'));
            return preisA - preisB;
        });
    } else if (sort[0] === 'preis_absteigend'){
        fahrzeuge.sort((a, b) => {
            let preisA = parseInt(a.getAttribute('data-preis_bis'));
            let preisB = parseInt(b.getAttribute('data-preis_bis'));
            return preisB - preisA;
        });
    } else if (sort[0] === 'km_aufsteigend'){
        fahrzeuge.sort((a, b) => {
            let kmA = parseInt(a.getAttribute('data-km_bis'));
            let kmB = parseInt(b.getAttribute('data-km_bis'));
            return kmA - kmB;
        });
    } else if (sort[0] === 'km_absteigend'){
        fahrzeuge.sort((a, b) => {
            let kmA = parseInt(a.getAttribute('data-km_bis'));
            let kmB = parseInt(b.getAttribute('data-km_bis'));
            return kmB - kmA;
        });
    } else if (sort[0] === 'ez_aufsteigend'){
        fahrzeuge.sort((a, b) => {
            let ezA = new Date(a.getAttribute('data-ez_ab'));
            let ezB = new Date(b.getAttribute('data-ez_ab'));
            return ezA - ezB;
        });
    } else if (sort[0] === 'ez_absteigend'){
        fahrzeuge.sort((a, b) => {
            let ezA = new Date(a.getAttribute('data-ez_ab'));
            let ezB = new Date(b.getAttribute('data-ez_ab'));
            return ezB - ezA;
        });
    } else if (sort[0] === 'ls_aufsteigend'){
        fahrzeuge.sort((a, b) => {
            let lsA = parseInt(a.getAttribute('data-leasing'));
            let lsB = parseInt(b.getAttribute('data-leasing'));
            return lsA - lsB;
        });
    } 

    let container = document.querySelector('.ce_rsce_fahrzeugSucheAPI .fahrzeuge');
    fahrzeuge.forEach(fahrzeug => {
        container.appendChild(fahrzeug);
    });
}

// ##### Filter reset #####
function resetFilter(){
    console.log('Reset Filter');
    filterObject = {
        freitext: [],
        klasse: [],
        marke: [],
        modell: [],
        karosserieform: [],
        fahrzeugart: [],
        aufbauart: [],
        ez_ab: [],
        km_bis: [],
        preis_bis: [],
        leistung: [],
        kraftstoff: [],
        getriebe: [],
        standort: [],
        anhaegekupplung: [],
        navigationssystem: [],
        allradantrieb: [],
        rueckfahrkamera: [],
        standheizung: [],
        ledscheinwerfer: [],
        panoramaschiebedach: [],
        klimaautomatik: [],
        sitzheizung: [],
        abstandstempomat: [],
        tempomat: [],
        soundsystem: [],
        fahrgestellnummer: [],
        fahrzeugnr: [],
        sort: ['preis_aufsteigend']
    };


    filterElements = {
        klasse: [],
        marke: [],
        modell: [],
        karosserieform: [],
    }


    let fahrzeuge = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .fahrzeug');

    fahrzeuge.forEach(fahrzeug => {
        fahrzeug.style.display = 'block';

    });

    let searchFilters = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .filter-container.select select, .ce_rsce_fahrzeugSucheAPI input');

    searchFilters.forEach(filter => {
        if (filter.tagName === 'INPUT'){
            if (filter.getAttribute('type') === 'checkbox'){
                filter.checked = false;
            } else {
                filter.value = "";
            }
        }
        if (filter.tagName === 'SELECT'){
            filter.selectedIndex = 0;
        }
    })

    document.querySelector('.ce_rsce_fahrzeugSucheAPI .filter-container.buttons select').selectedIndex = 1;

    document.querySelector('.ce_rsce_fahrzeugSucheAPI .infoBox .infoText').innerText = '';

    countCars();

    updateURL();

    dynamicFilter();

    sortCars();

}




function countCars(){
    let cars = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .fahrzeug');
    let counter = 0;

    cars.forEach(car => {
        if (car.style.display != 'none'){
            counter ++;
        }
    })
        
    searchButton.innerText = counter + ' FAHRZEUGE VERFÜGBAR';
}



function preFilter(){

    let fahrzeugsuche = document.querySelector('.ce_rsce_fahrzeugSucheAPI ');
    let filterKlasse = fahrzeugsuche.getAttribute('data-filterklasse');
    let filterMarke = fahrzeugsuche.getAttribute('data-filtermarke');
    let filterModell = fahrzeugsuche.getAttribute('data-filtermodell');

    fahrzeugsuche.querySelector('.headline').innerText = filterMarke + ' ' + filterModell + ' Fahrzeugsuche';

    setTimeout(()=> {
        if (filterKlasse){
            let select = fahrzeugsuche.querySelector('select[name=klasse]');
            for (let i = 0; i < select.options.length; i++) {
                if (select.options[i].text === filterKlasse) {
                    select.selectedIndex = i;
                    updateFilter(select);
                    break;
                }
            }
        }
        if (filterMarke){
            let select = fahrzeugsuche.querySelector('select[name=marke]');
            for (let i = 0; i < select.options.length; i++) {
                if (select.options[i].text === filterMarke) {
                    select.selectedIndex = i;
                    updateFilter(select);
                    break;
                }
            }
        }
        if (filterModell){
            let select = fahrzeugsuche.querySelector('select[name=modell]');
            for (let i = 0; i < select.options.length; i++) {
                if (select.options[i].text === filterModell) {
                    select.selectedIndex = i;
                    updateFilter(select);
                    break;
                }
            }
        }

    }, 300) 
}


// QuickFilter
function quickFilter(element){

    let quickfilters = document.querySelector('.ce_rsce_fahrzeugSucheAPI').querySelectorAll('.filter-icons .icon');
    quickfilters.forEach(quickfilter => {
        quickfilter.classList.remove('active');
    });
    element.parentElement.classList.add('active');
   
    let fahrzeugsuche = document.querySelector('.ce_rsce_fahrzeugSucheAPI ');
    let quickfilter = element.getAttribute('data-quickfilter');

    resetFilter();

    if (quickfilter === null){
        quickfilter = element.parentElement.getAttribute('data-quickfilter');
    }
    
    if (quickfilter === "Angebote"){
        let fahrzeuge = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .fahrzeug');
        fahrzeuge.forEach(fahrzeug => {
            if (fahrzeug.getAttribute('data-promo') === ""){
                fahrzeug.style.display = 'none';
                countCars();
            }
        })
        let select = fahrzeugsuche.querySelector('select[name=klasse]');
        select.selectedIndex = 1;
        filterObject.klasse = quickfilter;

        let fahrzeugContainer = document.querySelector('.ce_rsce_fahrzeugSucheAPI .filter-container.buttons');
        fahrzeugContainer.scrollIntoView({behavoir: 'smooth'});

    } else {

        let select = fahrzeugsuche.querySelector('select[name=klasse]');
        for (let i = 0; i < select.options.length; i++) {
            if (select.options[i].text === quickfilter) {
                select.selectedIndex = i;
                select.scrollIntoView({behavoir: 'smooth'});
                updateFilter(select);
                filterObject.klasse = quickfilter;
                break;
            }
        }
        
        // Quickfilter sollen Marken Select vorselektieren
        /*
        let markeSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name=marke]');
        filterSelectOptions(markeSelect, filterElements.marke);
        */
       
    }
}



// Formular senden auf Detailseite
function sendDetailForm(){
   
    let formName = document.getElementById('abs_name').value
    let formEmail = document.getElementById('email').value
    let formTel = document.getElementById('telefon').value
    let formMessage = document.getElementById('msg').value
    let crmEmpf = document.getElementById('empf').value
    let fahrzeugNr = document.getElementById('fzg_nr').value
    let fahrzeugLink = location.href;

    if (formName === "" || formEmail === "" || formTel === "" || formMessage === ""){
        console.log('Bitte alles ausfüllen')
    } else {
        console.log(formName)
        console.log(formTel)
        console.log(formEmail)
        console.log(formMessage)
        console.log(fahrzeugLink);
        console.log(crmEmpf);
        console.log(fahrzeugNr)

        let formData = new FormData();
        formData.append('formName', formName),
        formData.append('formTel', formTel);
        formData.append('formEmail', formEmail);
        formData.append('formMessage', formMessage);
        formData.append('fahrzeugLink', fahrzeugLink);
        formData.append('crmEmpf', crmEmpf);
        formData.append('fahrzeugNr', fahrzeugNr);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'fahrzeugAPI_Mailversand.php', true);
        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                
                    console.log(xhr.responseText)

                } else {
                    alert('Fehler beim Laden der Filter.');
                }
            }
        };
        xhr.send(formData);
    }
}